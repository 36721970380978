import React, { useState } from 'react';
import { LOCK_ASPECT_SHARE, LOCK_ASPECT_START_QUIZ, MODE_PUBLISH, MODE_UNPUBLISH, config } from "../Const.js";
import ImageSelector from './ImageSelector.jsx';
import QuizManager from './QuizManager.jsx';
import QuizSettings, { generateDefaultSettings } from './QuizSettings.jsx';
import { CircularProgress, Button, Box, Paper, Typography, Dialog, Checkbox, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import QuizMetadata from './QuizMetadata.jsx';
import { executeFetch } from '../Util.js';
import ValidationDialog from '../ValidationDialog.jsx';
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishIcon from '@mui/icons-material/Unpublished';
import PublishDialog from './PublishDialog.jsx';


function QuizManagePanel(props) {



    const saveUrlPath = "/api/v1/admin/quiz";
    const photosPath = "/api/v1/admin/entityData/photos";
    const loadQuizPath = "/api/v1/admin/quiz";

    const questionData = props.questionData;


    const [quizMetadata, setQuizMetadata] = useState(null);
    const [settings, setSettings] = useState(generateDefaultSettings());
    const [savePopupOpen, setSavePopupOpen] = useState(false);
    const [savePopupText, setSavePopupText] = useState("");
    const [quizStartImage, setQuizStartImage] = useState(null);
    const [quizShareImage, setQuizShareImage] = useState(null);
    const [quizPublished, setQuizPublished] = useState(false);
    const [managePopupOpen, setManagePopupOpen] = useState(false);
    const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);
    const [savingInProgress, setSavingInProgress] = useState(false);
    const [saveValidationPopupOpen, setSaveValidationPopupOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [publishPopupOpen, setPublishPopupOpen] = useState(false);

    const notifyNewQuiz = props.onNewQuiz;
    const notifyLoadQuiz = props.onLoad;

    // Define the original string with the existing quiz name
    const imageAltPrefixTemplate = 'Image in QuizMyDay <quiz_name> trivia quiz featuring ';

    const imageAltPrefixRegex = /(Image in QuizMyDay )(.*?)( trivia quiz featuring)/;


    const generateQuizMetadata = (quiz) => {
        return { 'questionList': questionData, 'quizName': quiz.label, 'description': quiz.description, 'site': 'music.quizmyday.com', 'seoTitle': quiz.seoTitle, 'serialNumber': quiz.serialNumber, 'createDate': quiz.createDate, 'id': quiz.id, 'tagList': quiz.tagList, 'mainTag': quiz.mainTag };
    }

    const generateQuizSettings = (quiz) => {
        return quiz.settings;
    }

    const handleSettingsSave = (s) => {

        setSettings(s);
        closeSettingsModal();

    }



    const handleLoadClicked = (e) => {
        setManagePopupOpen(true);
    }

    const handlePublishClicked = (e) => {

        setPublishPopupOpen(true);

    }

    const handlePublishClosed = (e) => {

        setPublishPopupOpen(false);

    }

    const handlePublishOk = (e) => {

        const publishUrlPath = `/api/v1/admin/quiz/${quizPublished ? 'unpublish' : 'publish'}`;

        let formData = new FormData();
        formData.append('quizId', quizMetadata.id);

        let url = config.SERVER_URL + publishUrlPath;

        let responseHandler = (result) => {

            setTimeout(() => {
                setPublishPopupOpen(false);
                setQuizPublished(!quizPublished)
            }
                , 1500);

        }

        executeFetch(url, "PATCH", null, formData, null, responseHandler);

    }



    const handleIncludesAudioIdentificationChange = (e) => {
        let checked = e.target.checked;
        let settingsClone = JSON.parse(JSON.stringify(settings))
        settingsClone.includesAudioIdentification = checked;
        setSettings(settingsClone);
    }

    const handleIncludesImageIdentificationChange = (e) => {
        let checked = e.target.checked;
        let settingsClone = JSON.parse(JSON.stringify(settings))
        settingsClone.includesImageIdentification = checked;
        setSettings(settingsClone);

    }

    const handlePopularChange = (e) => {
        let checked = e.target.checked;
        let settingsClone = JSON.parse(JSON.stringify(settings))
        settingsClone.popular = checked;
        setSettings(settingsClone);
    }

    const handleStartImageSelected = (imd) => {

        let imageAltPrefix = replaceQuizName(imageAltPrefixTemplate, quizMetadata?.quizName);

        imd.alt = imageAltPrefix + imd.description;
        setQuizStartImage(imd);
    }

    const handleShareImageSelected = (imd) => {
        let imageAltPrefix = replaceQuizName(imageAltPrefixTemplate, quizMetadata?.quizName);

        imd.alt = imageAltPrefix + imd.description;
        setQuizShareImage(imd);
    }


    const handleStartImageAltChange = (value) => {
        let quizStartImageClone = JSON.parse(JSON.stringify(quizStartImage));
        quizStartImageClone.alt = value;
        setQuizStartImage(quizStartImageClone);
    }

    const handleShareImageAltChange = (value) => {
        let quizShareImageClone = JSON.parse(JSON.stringify(quizShareImage));
        quizShareImageClone.alt = value;
        setQuizShareImage(quizShareImageClone);
    }

    const replaceAltByName = (imd, quizName) => {

        let clone = JSON.parse(JSON.stringify(imd));

        let newAlt = replaceQuizName(clone.alt, quizName);
        clone.alt = newAlt;

        return clone;

    }




    const replaceQuizName = (text, quizName) => {
        if (!quizName) quizName = '';
        return text.replace(imageAltPrefixRegex, `$1${quizName}$3`);
    }

    const handleQuizMetadataChanged = (qmd) => {

        setQuizMetadata(qmd);

        quizStartImage && setQuizStartImage(replaceAltByName(quizStartImage, qmd.quizName));
        quizShareImage && setQuizShareImage(replaceAltByName(quizShareImage, qmd.quizName));

    }

    const closeManagePopup = () => setManagePopupOpen(false);

    const closeSaveModal = () => setSavePopupOpen(false);

    const closeSaveValidationModal = () => setSaveValidationPopupOpen(false);

    const closeSettingsModal = () => setSettingsPopupOpen(false);

    const generateGalleryDataUrl = () => {

        let galleryDataUrl = null;
        let selectedEntityIds = [...new Set((questionData || []).map(q => {

            let qe = q.criteria.questionParams.quizzlerEntity;

            if (qe) {
                return qe.idMapping.WIKIDATA;
            } else {
                return null;
            }

        }).filter(q => q != null))];

        if (selectedEntityIds.length > 0) {
            let selectEntityIdsSearchStr = selectedEntityIds.join(",");
            galleryDataUrl = config.SERVER_URL + photosPath + "?wikidataIdList=" + selectEntityIdsSearchStr;
        }

        return galleryDataUrl;

    }

    const handleNewClicked = (e) => {


        setSettings(generateDefaultSettings());
        setQuizMetadata(generateQuizMetadata({}));
        setQuizStartImage(null);
        setQuizShareImage(null);
        setQuizPublished(false);

        notifyNewQuiz();

    }

    const handleLoadQuiz = (quiz) => {

        let quizId = quiz.id;

        let url = config.SERVER_URL + loadQuizPath + "/" + quizId;
        let responseHandler = (result) => {

            setQuizMetadata(generateQuizMetadata(result));
            setSettings(generateQuizSettings(result))
            notifyLoadQuiz(result.questionList, result.previewHtml);
            setQuizStartImage(result.startImageMetadata);
            setQuizShareImage(result.shareImageMetadata);
            setQuizPublished(result.published);

            setManagePopupOpen(false);
        }

        executeFetch(url, "get", null, null, null, responseHandler);

    }

    const generateQuiz = () => {


        var quiz = {};
        quiz.label = quizMetadata.quizName;
        quiz.description = quizMetadata.description;
        quiz.site = quizMetadata.site;
        quiz.seoTitle = quizMetadata.seoTitle;
        quiz.createDate = quizMetadata.createDate;
        quiz.id = quizMetadata.id;
        quiz.serialNumber = quizMetadata.serialNumber;
        quiz.tagList = quizMetadata.tagList;
        quiz.mainTag = quizMetadata.mainTag;
        quiz.published = quizPublished;
        quiz.settings = settings;

        if (quizStartImage) {
            if (!quizStartImage.alt) quizStartImage.alt = quizStartImage.description;
        }

        if (quizShareImage) {
            if (!quizShareImage.alt) quizShareImage.alt = quizShareImage?.description;
        }

        quiz.startImageMetadata = quizStartImage;
        quiz.shareImageMetadata = quizShareImage;

        let questionDataClone = JSON.parse(JSON.stringify(questionData));

        // Remove criteria that bulks up the payload
        questionDataClone.forEach(q => {

            delete q.criteria.contextQVariableList;
            delete q.criteria.correctOptionQVariableList;
            delete q.criteria.distractorQVariableList;
            delete q.criteria.quizzlerEntityList;
        })

        quiz.questionList = questionDataClone;

        return quiz;

    }

    const validateQuiz = () => {
        let validationFields = [];

        if (!quizMetadata?.quizName) validationFields.push('Label');
        if (!quizMetadata?.mainTag) validationFields.push('Main Tag');
        if (!quizStartImage) validationFields.push('Quiz Start Image');
        if (!quizShareImage) validationFields.push('Quiz Share Image');

        return validationFields;

    }

    const handleSaveClicked = (e) => {


        let validationErrors = validateQuiz();

        if (validationErrors.length > 0) {
            setValidationErrors(validationErrors);
            setSaveValidationPopupOpen(true);

        } else {

            setSavePopupOpen(true);
            setSavingInProgress(true);

            let quiz = generateQuiz();

            let formData = new FormData();
            formData.append('quizJson', JSON.stringify(quiz));

            let url = config.SERVER_URL + saveUrlPath;

            let responseHandler = (result) => {
                setSavingInProgress(false);

                handleLoadQuiz(result);
                setTimeout(() => setSavePopupOpen(false), 1500);
                setSavePopupText("Saved Successfully");
            }

            executeFetch(url, "post", null, formData, null, responseHandler);
        }

    }


    return (

        <Box sx={{ height: '100%' }}>
            <Typography variant="h6">Manage</Typography>
            <Box sx={{ height: 3 }}></Box>
            <Paper elevation={10} sx={{ height: '92%', overflowY: 'auto', overflowX: 'hidden', padding: '20px' }}>

                <Box sx={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        <Button variant="outlined" size="small" startIcon={<AddCircleIcon />} onClick={handleNewClicked}>New</Button>
                        <Button variant="outlined" size="small" startIcon={<ManageSearchIcon fontSize="small" />} onClick={handleLoadClicked}>Load</Button>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '5px' }}>

                        {!quizPublished &&
                            <Button variant="contained" sx={{
                                backgroundColor: 'green', color: 'white', '&:hover': {
                                    backgroundColor: 'green',
                                },
                            }} disabled={!quizMetadata?.id} size="small" startIcon={<PublishIcon fontSize="small" />} onClick={handlePublishClicked} >Publish</Button>
                        }

                        {quizPublished &&
                            <Button variant="contained" color={'error'} size="small" startIcon={<UnpublishIcon fontSize="small" />} onClick={handlePublishClicked}>Unpublish</Button>
                        }

                        <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={handleSaveClicked}>Save</Button>
                    </Box>

                </Box>

                <Dialog open={settingsPopupOpen} PaperProps={{
                    sx: {
                        width: 500,
                        height: 500

                    },
                }} onClose={closeSettingsModal}>

                    <QuizSettings settings={settings} onSave={handleSettingsSave} onCancel={closeSettingsModal} />
                </Dialog>
                <Box sx={{ height: 20 }}></Box>

                <QuizMetadata data={quizMetadata} questionData={questionData} onChange={handleQuizMetadataChanged} />



                <Box sx={{ mb: '20px' }}>

                    <InputLabel><Checkbox sx={{ padding: '5px' }} checked={settings.popular} onChange={handlePopularChange} />Popular Quiz</InputLabel>
                    <InputLabel><Checkbox sx={{ padding: '5px' }} checked={settings.includesImageIdentification} onChange={handleIncludesImageIdentificationChange} />Includes Image Identification</InputLabel>
                    <InputLabel><Checkbox sx={{ padding: '5px' }} checked={settings.includesAudioIdentification} onChange={handleIncludesAudioIdentificationChange} />Includes Audio Identification</InputLabel>
                </Box>


                <Dialog open={managePopupOpen} PaperProps={{
                    sx: {
                        width: '90%',
                        maxWidth: '90%',
                    },
                }} onClose={closeManagePopup}>
                    <QuizManager onLoadQuiz={handleLoadQuiz} />
                </Dialog>



                <Box sx={{ width: '100%' }}>
                    Quiz Start Image
                    <ImageSelector imd={quizStartImage} onAltChange={handleStartImageAltChange} lockAspect={LOCK_ASPECT_START_QUIZ} galleryDataUrl={generateGalleryDataUrl()} onSelect={handleStartImageSelected} ></ImageSelector>
                </Box>


                <Box sx={{ width: '100%', mt: '20px' }}>
                    Quiz Share Image
                    <ImageSelector imd={quizShareImage} onAltChange={handleShareImageAltChange} lockAspect={LOCK_ASPECT_SHARE} galleryDataUrl={generateGalleryDataUrl()} onSelect={handleShareImageSelected} ></ImageSelector>
                </Box>

                <ValidationDialog title={'Missing Fields'} open={saveValidationPopupOpen} errors={validationErrors} onClose={closeSaveValidationModal} />

                <PublishDialog mode={quizPublished ? MODE_UNPUBLISH : MODE_PUBLISH} quizLabel={quizMetadata?.quizName} open={publishPopupOpen} onClose={handlePublishClosed} onOk={handlePublishOk} />

                <Dialog open={savePopupOpen} PaperProps={{
                    sx: {
                        width: 400,
                        height: 100

                    },
                }} onClose={closeSaveModal}>

                    <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                        {savingInProgress && <Box sx={{ padding: '20px' }}><CircularProgress /></Box>}

                        {!savingInProgress && <Box sx={{ display: 'flex' }}><CheckCircleIcon fontSize='large' sx={{ color: 'green', marginRight: '10px' }}></CheckCircleIcon>
                            <Typography variant="h6">{savePopupText}</Typography></Box>}

                    </Box>
                </Dialog>

            </Paper >

        </Box >
    )
};

export default QuizManagePanel;