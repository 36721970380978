import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, Button } from '@mui/material';



function ImageCropper(props) {

    const [crop, setCrop] = useState();
    const [resizeRatio, setResizeRatio] = useState(null);
    const imd = props.imd;
    const imgDesc = imd.description;
    const imgUrl = imd.url;
    const lockAspect = props.lockAspect;
    const { aspect, minWidth } = lockAspect;

    const handleImageSelected = props.onSelect;

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const handleSelectButtonClicked = () => {

        if (crop) {
            imd.crop = { width: cr(crop.width), height: cr(crop.height), x: cr(crop.x), y: cr(crop.y) }
        }
        handleImageSelected(imd);

    }

    const cr = (num) => {
        return Math.round(num * resizeRatio);
    }


    const handleCropChange = (c) => {
        setCrop(c);
    }

    const handleImageLoaded = (e) => {
        let img = e.target;
        setResizeRatio(Math.min(img.naturalWidth / img.width), 1);
    }

    return (

        <Box sx={{ padding: '5px' }}>

            <ReactCrop
                crop={crop}
                onChange={handleCropChange}
                {...(lockAspect && { aspect, minWidth: minWidth / resizeRatio })}
            >
                <img alt={imgDesc} src={imgUrl} onLoad={handleImageLoaded} />
            </ReactCrop>

            <Box className="cropperInfoDiv">
                {crop &&
                    <Box className="">Cropped: x={cr(crop.x)}, y={cr(crop.y)}, {cr(crop.width)} x {cr(crop.height)}</Box>
                }

                <Box className="wrapper"></Box>
                <Box className="cropperInfoButtonDiv"><Button variant='contained' onClick={handleSelectButtonClicked}>Select</Button></Box>
            </Box>
            {renderHTML(imgDesc)}

        </Box >
    )

};

export default ImageCropper;
